import React, { useEffect, useState } from "react";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Button, SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import DeleteModal from "../../Components/Modals/DeleteModal";
import Toast from "../../Hooks/useToast";
import { getTeamsDetailBySlug, removePlayerBySlug } from "../../services/Team";
import { useParams } from "react-router-dom";
import { IoCaretForwardSharp } from "react-icons/io5";
import { IoIosRemoveCircle } from "react-icons/io";
import { Col, Row } from "react-bootstrap";
import { IoCaretBackSharp } from "react-icons/io5";
import { FaBackward } from "react-icons/fa";
import { FaForward } from "react-icons/fa";
import AddPlayerModal from "../../Components/Modals/AddPlayerModal";

const TeamDetailManagementScreen = ({ }) => {
  let { id, type, agegroup } = useParams();

  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState("");
  const [page, setPage] = useState(1);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addPlayerModal, setAddPlayerModal] = useState(false);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setRowData("");
  };

  const handleAddPlayerModalClose = () => {
    setAddPlayerModal(false);
    setRowData("");
  };

  const handleAddUser = () => {
    setAddPlayerModal(true);
  };

  function indication() {
    return indicationMessage;
  }

  const GetTeamsById = async () => {
    try {
      let result = await getTeamsDetailBySlug(id);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.message, "error");
    }
  };
  const RemovePlayer = async () => {
    try {
      const result = await removePlayerBySlug(id, rowData?.slug);
      GetTeamsById();
      setDeleteModal(false);
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.data?.message, "error");
    }
  };

  const handleBackPaginagtion = () => {
    if (paginationDetail?.current != 1) {
      setPage(page - 1);
    }
  };
  const handleForwardPaginagtion = () => {
    if (paginationDetail?.current != paginationDetail?.total) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    GetTeamsById();
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
    },

    {
      dataField: "coach_image_url",
      text: "Image",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        if (row.image_url) {
          return (
            <div className="d-flex align-items-center">
              <img
                src={row?.image_url}
                style={{
                  height: "40px",
                  maxHeight: "50px",
                  borderRadius: "50%",
                  margin: "10px",
                  padding: "5px",
                }}
              />
            </div>
          );
        }
      },
    },
    {
      dataField: "slug",
      text: "Remove Player",
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              handleDeleteModalOpen();
              setRowData(row);
            }}
          >
            <IoIosRemoveCircle color="red" size={20} cursor={"pointer"} />
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          deletetxt={"Delete"}
          data={rowData}
          id={id}
          onDelete={RemovePlayer}
        />
      ) : (
        ""
      )}

      {addPlayerModal ? (
        <AddPlayerModal
          handleAddPlayerModalClose={handleAddPlayerModalClose}
          data={rowData}
          teamSlug={id}
          agegroup={agegroup}
          GetTeamsById={GetTeamsById}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={`Team Detail Management (${type.toUpperCase()}ED)`} />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} `}
              style={{
                backgroundColor: "green",
              }}
              text="Add Player"
              onClick={handleAddUser}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={data} columns={columns} indication={indication} />
        <div
          className="pagination"
          style={{
            fontSize: "15px",
            color: "white",
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            marginRight: "12px",
          }}
        >
          <FaBackward
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={() => setPage(1)}
          />
          <IoCaretBackSharp
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={handleBackPaginagtion}
          />
          <span>
            {paginationDetail?.current}/{paginationDetail?.total}
          </span>
          <IoCaretForwardSharp
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={handleForwardPaginagtion}
          />
          <FaForward
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={() => setPage(paginationDetail?.total)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamDetailManagementScreen;

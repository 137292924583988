import React, { useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import CoachManagementScreen from './CoachManagementScreen';

export default function CoachManagement() {

    const [page, setPage] = useState(ROUTES.COACH_MANAGEMENT)

    switch (page) {
        case ROUTES.COACH_MANAGEMENT:
            return <CoachManagementScreen setPage={setPage} />
        default:
            return <Login />
    }


}

import React, { useState } from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  CustomInput,
  Description,
  Input,
  Title,
} from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { useForm, Controller } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import Toast from "../../Hooks/useToast";
import { useNavigate } from "react-router-dom";
import { LoginAPI } from "../../services/auth";
import {
  SetAuthUserLocalStorage,
  SetTokenLocalStorage,
} from "../../services/Localstorage";

export default function Login({ setPage }) {
  const { handleSubmit, control, register } = useForm();
  const navigate = useNavigate();
  // const pagename = useSelector((state) => state.pagename.pagename);

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleLogin = async (data) => {
    data["device_type"] = "234";
    data["device_token"] = "andriod";
    try {
      const response = await LoginAPI(data);
      SetAuthUserLocalStorage(response?.data?.data);
      SetTokenLocalStorage(response?.data?.data?.api_token);
      navigate("dashboard");
      Toast(response?.data?.message, "success");
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <AccountLayout>
      <Description text="Hello there," />
      <Title text="Welcome back" classes="mb-2" />
      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                type="password"
                placeholder="Password"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text="Login"
          classes="mt-3 mb-2"
          type="submit"
          // onClick={handleSubmit}
        />
      </Form>
      <Description
        classes="ms-auto cursor-pointer"
        text="Forgot Password ?"
        onClick={handleForgotPasswordClick}
      />
    </AccountLayout>
  );
}

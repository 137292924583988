import React, { useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import PlayerManagementScreen from './PlayerManagementScreen';

export default function PlayerManagement() {

    const [page, setPage] = useState(ROUTES.PLAYER_MANAGEMENT)

    switch (page) {
        case ROUTES.PLAYER_MANAGEMENT:
            return <PlayerManagementScreen setPage={setPage} />
        default:
            return <Login />
    }


}

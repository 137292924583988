import React, { useEffect, useState } from 'react'
import { ROUTES } from './Route'
import styles from './index.module.css';
import { BackButton, SubTitle } from '../../Components/CustomComponents';
import { Col, Row } from 'react-bootstrap';
import TournamentBanner from '../../Components/Cards/TournamentBanner';
import { getScheduleTournament } from '../../services/generalService';

const ScheduledTournamentScreen = ({ setSlug, scheduleData, setPage }) => {

    const handleBackButtonClick = () => {
        setPage(ROUTES.TOURNAMENT)
    }

    const gotoTournamentDetails = (data) => {
        setSlug(data?.slug)
        setPage(ROUTES.TOURNAMENT_DETAILS);
    }


    return (
        <div className={styles.LiveMatches}>
            <BackButton isRelative={true} handleBackButton={handleBackButtonClick} />
            <SubTitle
                text={'Scheduled Tournaments'}
                classes=''
            />

            <Row
                className={`w-100 mt-2 mb-5`}
                gutter={[0, 24]}
            >
                {scheduleData?.map(item =>
                    <Col
                        className={` mt-2 mb-2`}
                        lg={6} md={6} sm={12}
                    >
                        <TournamentBanner data={item} hasBackButton={false} handleOnClick={() => gotoTournamentDetails(item)} />

                    </Col>)}
            </Row>

        </div>
    )
}

export default ScheduledTournamentScreen

import React from 'react';
import { Button } from '../CustomComponents';
import cssStyles from './styles/index.module.css';
import { Col } from 'react-bootstrap';
import { RiRefund2Line } from "react-icons/ri";

const TeamAgeCard = ({ data, sNo, handleDeleteModalOpen }) => {

  const handleDeleteMemeber = (id) => {
    console.log("changed");

  };

  return (
    // <div style={{ display: 'flex', background: '#222232', borderRadius: '10px', }}>

    <div className='p-2 rounded-4' style={{ background: '#222232', display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
      <Col
        md={8}
        // xs={{ order: 2, span: 12 }}
        // sm={{ order: 1, span: 12 }}
        // md={{ order: 1, span: 12 }}

        // lg={{ order: 1, span: 8 }}
        // xl={{ order: 1, span: 8 }}
        // xxl={{ order: 1, span: 8 }}
        style={{
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'center',
        }}
      >

        <span className='p-2' > {sNo + 1} </span>

        <img

          style={{ width: '45px', height: '45px', margin: '0 10px', }}
          src={'/images/team_logo_1.png'}
          alt="Team 2 Logo"
        />

        <p className=' fw-bold lc-1 m-0'> {data?.name} </p>

      </Col>

      <Col
        md={4}
        // xs={{ order: 2, span: 12 }}
        // sm={{ order: 2, span: 12 }}
        // md={{ order: 2, span: 12 }}

        // lg={{ order: 2, span: 4 }}
        // xl={{ order: 2, span: 4 }}
        // xxl={{ order: 2, span: 4 }}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '2px',
        }} >

        <span onClick={handleDeleteModalOpen}>
          <RiRefund2Line color='#EC5252' />
        </span>



      </Col>
    </div >

  )
}

export default TeamAgeCard



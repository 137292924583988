import React, { useState } from "react";
import { Form } from "react-bootstrap";
import filterStyles from "./selectFieldComponent.module.css";

const SelectFieldComponent = ({ optionsList, firstOption, onSelectChange }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelectChange(value); // Send selected value to parent
  };

  return (
    <div>
      <select
        className={`${filterStyles.select}`}
        value={selectedValue}
        onChange={handleSelectChange}
      >
        <option value="" selected disabled hidden>
          {" "}
          {firstOption}{" "}
        </option>
        {optionsList.map((value, index) => (
          <option value={value} key={index}>
            {" "}
            {value}{" "}
          </option>
        ))}
      </select>
    </div>
  );
};

/*#region: Developer 66*/
export const CustomSelect = ({
  name = "",
  options = [],
  placeholder = "",
  classes = "mt-1 mb-2",
  autoFocus = false,
  onChange,
  defaultValue,
  value,
}) => {
  return (
    <Form.Select
      name={name}
      className={`${filterStyles.select}`}
      aria-label={placeholder}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <option value="">{placeholder}</option>
      {options?.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </Form.Select>
  );
};
export default SelectFieldComponent;

import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/notification.module.css";
import { Col, Row, Spinner } from "react-bootstrap";
import { IoIosAddCircle } from "react-icons/io";
import { CustomSelect } from "../../Pages/TournamentManagement/Components/SelectFieldComponent";
import {
  getAllAgeGroup,
  getAllAgeGroupUnregTeam,
} from "../../services/generalService";
import { addPlayerBySlug } from "../../services/Team";
import Toast from "../../Hooks/useToast";
import { FaCircleXmark } from "react-icons/fa6";

const AddPlayerModal = ({
  handleAddPlayerModalClose,
  data,
  teamSlug,
  agegroup,
  GetTeamsById
}) => {
  const [rowId, setRowId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allAgeGroups, setAllAgeGroups] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [ageGroup, setAgeGroup] = useState(undefined);
  const [unregTeammember, setUnregTeammember] = useState([]);
  const [loading, setLoading] = useState(false)
  const [mounted, setMounted] = React.useState(false);

  // const GetAllAgeGroup = async () => {
  //   const res = await getAllAgeGroup();
  //   setAllAgeGroups(res?.data?.data);
  // };
  const GetAllAgeGroupUnregTeam = async () => {
    setLoading(true)
    const res = await getAllAgeGroupUnregTeam(agegroup);
    setUnregTeammember(res?.data?.data);
    setLoading(false)
  };
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      setRowId(data.id);
      setUserName(data.name);
      setUserEmail(data.email);
      setSelectedRole(data.role);
      console.log(data.role);
      console.log(selectedRole);
    }
  }, [data, mounted]);

  useEffect(() => {
    GetAllAgeGroupUnregTeam();
  }, [agegroup]);

  const handleUserName = (e) => {
    console.log(e.target.value);
    setUserName(e.target.value);
  };

  const handleUserEmail = (e) => {
    console.log(e.target.value);
    setUserEmail(e.target.value);
  };

  const handlePassword = (e) => {
    console.log(e.target.value);
    setPassword(e.target.value);
  };

  const handleRoleChange = (value) => {
    console.log(value);
    setSelectedRole(value);
  };

  const handleSubmit = (e) => {
    console.log(userName);
    console.log(userEmail);
    console.log(password);
    console.log(selectedRole);
  };

  if (!mounted) {
    return;
  }

  const PlayerAgeGroup = [
    { value: 1, label: "faizan" },
    { value: 2, label: "faizan" },
    { value: 3, label: "faizan" },
    { value: 4, label: "faizan" },
    { value: 5, label: "faizan" },
    { value: 6, label: "faizan" },
    { value: 7, label: "faizan" },
  ];

  const Playerdata = [
    { slug: 1, name: "faizan" },
    { slug: 2, name: "faizan" },
    { slug: 3, name: "faizan" },
    { slug: 4, name: "faizan" },
    { slug: 5, name: "faizan" },
    { slug: 6, name: "faizan" },
    { slug: 7, name: "faizan" },
  ];

  const addPlayer = async (data) => {
    try {
      const result = await addPlayerBySlug(teamSlug, data?.slug);
      GetAllAgeGroupUnregTeam()
      GetTeamsById()
      Toast(result?.data?.message, "success");
    } catch (e) {
      Toast(e?.response?.data?.message, "error");
    }
  };
  return (
    <div>
      <ModalContainer handleClose={handleAddPlayerModalClose}>
        <div className={cssStyles.custommodal}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">All Unregister Players</p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleAddPlayerModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>


          {loading ? <Spinner /> : unregTeammember && unregTeammember?.length > 0 ? (
            unregTeammember?.map((dt) => (
              <div className={`${cssStyles.data} mt-3`} key={dt?.slug}>
                <div>{dt?.fullname}</div>
                <div>
                  <IoIosAddCircle
                    color="green"
                    size={20}
                    cursor={"pointer"}
                    onClick={() => addPlayer(dt)}
                  />
                </div>
              </div>
            ))
          ) : (
            <p>No Data Found</p>
          )}
        </div>
      </ModalContainer>
    </div>
  );
};

export default AddPlayerModal;

import React, { useEffect, useState } from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/notification.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { Col, Row } from "react-bootstrap";
import { Input, Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";

const EditUserModal = ({ handleEditModalClose, data }) => {
  const [rowId, setRowId] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");

  const [selectedRole, setSelectedRole] = useState("");

  const roleList = ["Admin", "Moderator", "Editor"];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {

    if (data) {
      setRowId(data.id);
      setUserName(data.name);
      setUserEmail(data.email);
      setSelectedRole(data.role);
      console.log(data.role)
      console.log(selectedRole)
    }

  }, [data, mounted]);


  const handleUserName = (e) => {
    console.log(e.target.value);
    setUserName(e.target.value);
  };

  const handleUserEmail = (e) => {
    console.log(e.target.value);
    setUserEmail(e.target.value);
  };

  const handlePassword = (e) => {
    console.log(e.target.value);
    setPassword(e.target.value);
  };

  const handleRoleChange = (value) => {
    console.log(value);
    setSelectedRole(value);
  };

  const handleSubmit = (e) => {
    console.log(userName);
    console.log(userEmail);
    console.log(password);
    console.log(selectedRole);
  }


  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0"> {data ? "Edit User" : "Add User"} </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Row>
              <Col md={12}>
                <p className="m-0" style={{ color: "#0AA7FF" }}>
                  User Name
                </p>
                <Input
                  classes="mt-2 mb-3"
                  type="text"
                  placeholder="Enter user name"
                  value={userName}
                  onChange={handleUserName}
                />
              </Col>
            </Row>
            {data ? '' :
              <Row>
                <Col md={12}>
                  <p className="m-0" style={{ color: "#0AA7FF" }}>
                    User Email
                  </p>
                  <Input
                    classes="mt-2 mb-3"
                    type="email"
                    placeholder="Enter user email"
                    value={userEmail}
                    onChange={handleUserEmail}
                  />
                </Col>
              </Row>
            }
            {data ? '' :
              <Row>
                <Col md={12}>
                  <p className="m-0" style={{ color: "#0AA7FF" }}>
                    Password
                  </p>
                  <Input

                    classes="mt-2 mb-3"
                    type="password"
                    placeholder="Enter password"
                    value={password}

                    onChange={handlePassword}
                  />
                </Col>
              </Row>
            }
            <Row>
              <Col md={12}>
                <p className="m-0" style={{ color: "#0AA7FF" }}>
                  Role
                </p>
                <SelectFieldComponent
                  firstOption={selectedRole ? selectedRole : "Select Role"}
                  optionsList={roleList}
                  onSelectChange={handleRoleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 `}
                  text={data ? "Update" : "Save"}
                  onClick={handleSubmit}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditUserModal;

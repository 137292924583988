import React, { useEffect, useState } from 'react'

import { ROUTES } from "./Route";
import Login from '../Account/Login';
import TournamentManagementScreen from './TournamentManagementScreen';
import GetRegisterScreen from './GetRegister';
import MagicBoxScreen from './MagicBox';
import LiveTournamentScreen from './LiveTournamentScreen';
import ScheduledTournamentScreen from './ScheduledTournamentScreen';
import TournamentDetailsScreen from './TournamentDetailsScreen';
import CreateTournamentScreen from './CreateTournamentScreen';
import { getAllAgeGroup, getLiveTournament, getScheduleTournament, getTournamentBySlug } from '../../services/generalService';

export default function Tournaments() {

  const [page, setPage] = useState(ROUTES.TOURNAMENT)
  const [liveData, setLiveData] = useState([])
  const [scheduleData, setScheduleData] = useState([])
  const [allAgeGroups, setAllAgeGroups] = useState([])
  console.log(allAgeGroups, 'allAgeGroups 555');
  const [liveAllData, setLiveAllData] = useState([])
  const [scheduleAllData, setScheduleAllData] = useState([])
  const [isApiLoading, setIsApiLoading] = useState(false)

  const [slug, setSlug] = useState(null)
  useEffect(() => {
    GetLiveTournament()
    GetScheduleTournament()
    GetAllAgeGroup()
  }, [])

  const GetLiveTournament = async () => {
    setIsApiLoading(true)
    const response = await getLiveTournament()
    const topResponse = response?.data?.data?.length > 0 ? response?.data?.data?.slice(0, 2) : response?.data?.data
    setLiveData(topResponse)
    setLiveAllData(response?.data?.data);
    setIsApiLoading(false)
  }

  const GetScheduleTournament = async () => {
    setIsApiLoading(true)
    const response = await getScheduleTournament()
    const topResponse = response?.data?.data?.length > 0 ? response?.data?.data?.slice(0, 2) : response?.data?.data
    setScheduleData(topResponse);
    setScheduleAllData(response?.data?.data)
    setIsApiLoading(false)
  }
  const GetAllAgeGroup = async () => {
    const res = await getAllAgeGroup()
    setAllAgeGroups(res?.data?.data)
  }


  switch (page) {
    case ROUTES.TOURNAMENT:
      return <TournamentManagementScreen isApiLoading={isApiLoading} allAgeGroups={allAgeGroups} setSlug={setSlug} liveData={liveData} scheduleData={scheduleData} setPage={setPage} />
    case ROUTES.LIVE_TOURNAMENTS:
      return <LiveTournamentScreen allAgeGroups={allAgeGroups} setSlug={setSlug} liveData={liveAllData} setPage={setPage} />
    case ROUTES.SCHEDULED_TOURNAMENTS:
      return <ScheduledTournamentScreen allAgeGroups={allAgeGroups} setSlug={setSlug} scheduleData={scheduleAllData} setPage={setPage} />
    case ROUTES.TOURNAMENT_DETAILS:
      return <TournamentDetailsScreen allAgeGroups={allAgeGroups} setSlug={setSlug} slug={slug} setPage={setPage} GetScheduleTournament={GetScheduleTournament} />
    case ROUTES.LIVE_TOURNAMENT_DETAILS:
      return <TournamentDetailsScreen allAgeGroups={allAgeGroups} setPage={setPage} slug={slug} live={true} GetScheduleTournament={GetScheduleTournament} />
    case ROUTES.CREATE_TOURNAMENT:
      return <CreateTournamentScreen allAgeGroups={allAgeGroups} setPage={setPage} GetScheduleTournament={GetScheduleTournament} />
    default:
      return <Login />
  }


}

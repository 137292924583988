import { apiUrl } from "../constants";
import api from "./indexGeneral";

export const getTeamsRegistered = (type, params) =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.team}/${type}`, { params });

export const getTeamsDetailBySlug = (slug) =>
  api.get(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.team}/${slug}/${apiUrl.member}`
  );

export const addPlayerBySlug = (team_slug, player_slug) =>
  api.post(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.team}/${team_slug}/${apiUrl.member}/${player_slug}`
  );

export const removePlayerBySlug = (team_slug, player_slug) =>
  api.delete(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.team}/${team_slug}/${apiUrl.member}/${player_slug}`
  );

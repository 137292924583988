import React from "react";
import { Badge } from "react-bootstrap";

const TimeBadge = ({ text, color, textColor, }) => {
  const badgeStyle = {
    color: textColor || "#0AA7FF",
    backgroundColor: color || "#fff",
    fontSize: "1rem",
    // fontWeight: "bold",
    borderRadius: "16px",
    width: 'fit-content',
    margin: '2px',
  };

  return (
    <Badge className="p-3" bg="" style={badgeStyle}>
      {text}
    </Badge>
  );
};

export default TimeBadge;

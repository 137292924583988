import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { WEB_ROUTES } from "./constants";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute";

import Account from "./Pages/Account";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import Dashboard from "./Pages/Dashboard";
// import Tournaments from "./Pages/Tournaments";
// import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
// import Profile from "./Pages/Profile";
// import PaymentMethod from "./Pages/PaymentMethod";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";
import UserManagement from "./Pages/UserManagement";
import PlayerManagement from "./Pages/PlayerManagement";
import CoachManagement from "./Pages/CoachManagement";
import TournamentManagementScreen from "./Pages/TournamentManagement/TournamentManagementScreen";
import TournamentManagement from "./Pages/TournamentManagement";
import TeamManagement from "./Pages/TeamManagement";
import TeamDetailManagementScreen from "./Pages/TeamDetailPage/TeamDetailManagementScreen";
function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route
        exact
        path={baseRoute(WEB_ROUTES.ACCOUNT)}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        exact
        path={baseRoute(WEB_ROUTES.DASHBOARD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        exact
        path={baseRoute(WEB_ROUTES.TOURNAMENT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={1}>
              <TournamentManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        exact
        path={baseRoute(WEB_ROUTES.PLAYER_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={true} selectedRoute={2}>
              <PlayerManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        exact
        path={baseRoute(WEB_ROUTES.COACH_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={true} selectedRoute={3}>
              <CoachManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      // <Route
      //   exact
      //   path={baseRoute(WEB_ROUTES.USER_MANAGEMENT)}
      //   element={
      //     <ProtectedRoute>
      //       <ProtectedLayout isSearchBar={true} selectedRoute={4}>
      //         <UserManagement />
      //       </ProtectedLayout>
      //     </ProtectedRoute>
      //   }
      // />,
      <Route
        exact
        path={baseRoute(WEB_ROUTES.TEAM_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={true} selectedRoute={4}>
              <TeamManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        exact
        path={baseRoute(
          `${WEB_ROUTES.TEAM_DETAIL_MANAGEMENT}/:id/:type/:agegroup`
        )}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={true} selectedRoute={4}>
              <TeamDetailManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        exact
        path={baseRoute(WEB_ROUTES.SETTING)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={5}>
              <Setting />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      // <Route exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={false} selectedRoute={2}>
      //       <Profile />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route
          path={baseRoute("*")}
          element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />}
        />
      </Routes>
    </Router>
  );
}

export default App;

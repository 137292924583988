import { apiUrl } from "../constants";
import api from "./indexGeneral";

export const getAllAgeGroup = (id) =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.ageGroup}`);
export const getLiveMatches = (id) =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${id}/live-match`);
export const startMatch = (data) =>
  api.put(`${apiUrl.api}/${apiUrl.role}/${apiUrl.startmatch}`, data);
export const endMatch = (data) =>
  api.post(`${apiUrl.api}/${apiUrl.role}/${apiUrl.endmatch}`, data);

export const getLiveTournament = () =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.livetournament}/`);
export const getScheduleTournament = () =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.scheduletournament}/`);

// get tournament By Slug/id
export const getTournamentBySlug = (id, params) =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${id}`, {
    params,
  });
export const getTeamsByAgeGroup = (id) =>
  api.get(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${apiUrl.ageGroup}/${id}/team`
  );

// create Fixture
export const createFixture = (id, data) =>
  api.post(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${apiUrl.ageGroup}/${id}/fixture`,
    data
  );
export const getFixtureBySlug = (id, params) =>
  api.get(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.tournament}/${apiUrl.ageGroup}/${id}/fixture`,
    { params }
  );

export const refundTeam = (data) =>
  api.put(`${apiUrl.api}/${apiUrl.role}/${apiUrl.refund}`, data);

export const getAllAgeGroupUnregTeam = (slug) =>
  api.get(
    `${apiUrl.api}/${apiUrl.role}/${apiUrl.ageGroup}/${slug}/${apiUrl.player}/${apiUrl?.unregister}`
  );

import React, { useState } from 'react';

import styles from './LiveMatchStats.module.scss'
import cssStyles from "../index.module.css";

import BorderedCard from '../../../Components/Cards/BorderedCard';
import { CardDescription, CardTitle, CustomInput, LiveBadge, Score } from '../../../Components/CustomComponents';
import { Controller } from "react-hook-form"


export default function UpdateScoreModal({ control, liveMatches, index, handlePreviousClick, handleNextClick, onStart, isEdit = false, onClick = () => { } }) {



    return (
        <>

            < BorderedCard >
                <div className={`${styles.matchInfo} ${!isEdit ? styles.editable : ""}`} >
                    <div className={styles.teamContainer}>
                        <div className={styles.team}>
                            <img
                                src={liveMatches[index]?.teamA_image_url}
                                alt='Team 1'
                                className={`${styles.logo}`}
                            />

                            <CardTitle
                                text={liveMatches[index]?.teamA_name}
                                classes="fs-8"
                            />
                        </div>
                        <div className={styles.seperator}>
                            <LiveBadge classes="mb-1" />
                            <CardTitle
                                text={`Round ${liveMatches[index]?.round}`}
                                classes='fs-5'
                            />
                            <CardDescription
                                text={liveMatches[index]?.start_date}
                                classes="fs-8"
                            />
                            <span className={styles.vs}>VS</span>
                        </div>
                        <div className={styles.team}>
                            <img
                                src={liveMatches[index]?.teamB_image_url}
                                alt='Team 1'
                                className={`${styles.logo}`}
                            />

                            <CardTitle
                                text={liveMatches[index]?.teamB_name}
                                classes="fs-8"
                            />
                        </div>
                    </div>
                    <div className={styles.statsContainer}>
                        <div className={styles.team}>
                            <CardTitle
                                text="Away"
                                classes="mb-1"
                            />
                            {/* <Score
                                text={liveMatches[index]?.score?.away}
                                classes="p-2 fw-normal fs-5"
                            /> */}
                            <Controller
                                control={control}
                                name="away"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <CustomInput placeholder={''} onChange={onChange} />
                                )}
                            />
                        </div>
                        <div className={styles.stats}>
                            <div className={styles.stat}>
                                <Controller
                                    control={control}
                                    name="innings"
                                    rules={{
                                        required: { value: true, message: 'Required Field' },
                                        maxLength: { value: 3, message: 'max Length is 100' },
                                    }}                                    
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <CustomInput placeholder={'Innings'} onChange={onChange} />
                                    )}
                                />
                                {/* <Score text={liveMatches[index]?.match_no} classes="p-2 fs-8" />
                                <CardDescription text='Innings' /> */}
                            </div>
                            <div className={styles.ballContainer}>
                                <div className={styles.stat}>
                                    <Controller
                                        control={control}
                                        name="ball"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <CustomInput placeholder={'Ball'} onChange={onChange} />
                                        )}
                                    />
                                    {/* <Score text={liveMatches[index]?.score?.ball} classes="p-2 fs-8" />
                                    <CardDescription text={'Ball'} /> */}
                                </div>
                                <div className={styles.stat}>
                                    <Controller
                                        control={control}
                                        name="strike"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <CustomInput placeholder={'Strike'} onChange={onChange} />
                                        )}
                                    />
                                    {/* <Score text={liveMatches[index]?.score?.strike} classes="p-2 fs-8" />
                                    <CardDescription text='Strike' /> */}
                                </div>
                                <div className={styles.stat}>
                                    <Controller
                                        control={control}
                                        name="out"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <CustomInput placeholder={'Out'} onChange={onChange} />
                                        )}
                                    />
                                    {/* <Score text={liveMatches[index]?.score?.out} classes="p-2 fs-8" />
                                    <CardDescription text='Out' /> */}
                                </div>
                            </div>
                        </div>
                        <div className={styles.team}>
                            <CardTitle
                                text="Home"
                                classes="mb-1"
                            />
                            {/* <Score
                                text={liveMatches[index]?.score?.home}
                                classes="p-2 fw-normal fs-5"
                            /> */}
                            <Controller
                                control={control}
                                name="home"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <CustomInput placeholder={''} onChange={onChange} />
                                )}
                            />

                        </div>
                    </div>
                </div>
            </BorderedCard >
        </>

    )
}

import React, { useEffect, useState } from "react";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import Toast from "../../Hooks/useToast";
import EditUserModal from "../../Components/Modals/EditUserModal";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { TeamCheckList } from "../../constants";
import { CustomSelect } from "../TournamentManagement/Components/SelectFieldComponent";
import { getTeamsRegistered } from "../../services/Team";
import { IoCaretForwardSharp } from "react-icons/io5";
import { IoCaretBackSharp } from "react-icons/io5";
import { FaBackward } from "react-icons/fa";
import { FaForward } from "react-icons/fa";

const TeamManagementScreen = ({ }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState("");
  const [type, setType] = useState("register");
  const [editModal, setEditModal] = useState(false);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [page, setPage] = useState(1);

  const handleEditModalClose = () => {
    setEditModal(false);
    setRowData("");
  };

  const onselectDropDown = (data) => {
    setType(data.target.value);
  };

  const GetTeamsRegistered = async () => {
    setLoader(true);
    let params = {
      page: page,
    };
    try {
      let result = await getTeamsRegistered(type, params);
      setLoader(false);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
    } catch (e) {
      setLoader(false);
      Toast(e?.response?.message, "error");
    }
  };

  useEffect(() => {
    GetTeamsRegistered();
  }, [type, page]);

  function indication() {
    return indicationMessage;
  }
  const isTournamentNamePresent = data.some((row) => row.tournament_name);
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    ...(isTournamentNamePresent
      ? [
        {
          dataField: "tournament_name",
          text: "Tournament Details",
          sort: true,
          headerStyle: {
            width: "200px",
          },
          formatter: (cellContent, row) => {
            if (row.tournament_name) {
              return (
                <div className="d-flex align-items-center">
                  <img
                    src={row?.tournament_image_url}
                    style={{
                      height: "40px",
                      maxHeight: "50px",
                      borderRadius: "50%",
                      margin: "10px",
                      padding: "5px",
                    }}
                  />
                  <span>{row?.tournament_name}</span>
                </div>
              );
            }
          },
        },
      ]
      : []),
    {
      dataField: "coach_image_url",
      text: "Coach Details",
      sort: true,
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.coach_name) {
          return (
            <div className="d-flex align-items-center">
              <img
                src={row?.coach_image_url}
                style={{
                  height: "40px",
                  maxHeight: "50px",
                  borderRadius: "50%",
                  margin: "10px",
                  padding: "5px",
                }}
              />
              <span>{row?.coach_name}</span>
            </div>
          );
        }
      },
    },

    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2`}
                role="button"
                onClick={() => {
                  navigate(
                    `/team-detail-management/${row?.slug}/${type}/${row?.group_type_slug}`
                  );
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <FaEye />
                </span>
                <span>View</span>
              </div>
            </div>
          );
        }
      },
    },
  ];
  const handleBackPaginagtion = () => {
    if (paginationDetail?.current != 1) {
      setPage(page - 1);
    }
  };
  const handleForwardPaginagtion = () => {
    if (paginationDetail?.current != paginationDetail?.total) {
      setPage(page + 1);
    }
  };
  return (
    <div className={styles.MainContainer}>
      {editModal ? (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={rowData}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={`Team Management (${type.toUpperCase()}ED)`} />
        </div>
        <Row>
          <Col md={12}>
            <CustomSelect
              placeholder="Select"
              options={TeamCheckList}
              value={TeamCheckList[1]?.value}
              onChange={onselectDropDown}
            />
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        <Table data={data} columns={columns} indication={indication} />
        <div
          className="pagination"
          style={{
            fontSize: "15px",
            color: "white",
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            marginRight: "12px",
          }}
        >
          <FaBackward
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={() => setPage(1)}
          />
          <IoCaretBackSharp
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={handleBackPaginagtion}
          />
          <span>
            {paginationDetail?.current}/{paginationDetail?.total}
          </span>
          <IoCaretForwardSharp
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={handleForwardPaginagtion}
          />
          <FaForward
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={() => setPage(paginationDetail?.total)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamManagementScreen;

import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Tabs from '../../Components/Tabs/Tabs'
import AddTournamentComponent from '../../Components/Modals/AddTournamentDetails';
import TreeComponent from './Components/TreeComponent';
import RefundPaymentModal from '../../Components/Modals/RefundPaymentModal';
import { getFixtureBySlug, getTeamsByAgeGroup } from '../../services/generalService';

const TablesContent = ({ allAgeGroups, data }) => {

  const [addModalShow, setAddModalShow] = useState(false)
  const ageGroup = data?.age_groups
  const defaultAgeGroup = ageGroup?.length > 0 ? ageGroup[0]?.value : 0;
  const [key, setKey] = useState(defaultAgeGroup);
  const [teams, setTeams] = useState([])
  const [fixture, setFixture] = useState({})
  console.log(teams, 'teamsteams!!@');
  const [addRefundModalShow, setAddRefundModalShow] = useState(false)

  useEffect(() => {
    if (key !== null) {
      GetTeamsByAgeGroup()
      GetFixtureBySlug()
    }
  }, [key])
  const GetTeamsByAgeGroup = async () => {
    const res = await getTeamsByAgeGroup(key)
    setTeams(res?.data?.data)
  }

  const GetFixtureBySlug = async () => {
    const res = await getFixtureBySlug(key)
    setFixture(res?.data?.data)
  }




  const inlineItemStyles = {
    background: '#222232',
    margin: '5px',
    padding: '10px 20px',
  };


  // const tabContent = () => {
  // switch (key) {
  //   case "6u":
  //     return <TeamsTabContent length='6' />;
  //   case "8u":
  //     return <TeamsTabContent length='8' />;
  //   case "10u":
  //     return <TeamsTabContent length='10' />;
  //   case "12u":
  //     return <TeamsTabContent length='12' />;
  //   case "14u":
  //     return <TeamsTabContent length='14' />;
  //   case "16u":
  //     return <TeamsTabContent length='16' />;
  //   case "18u":
  //     return <TeamsTabContent length='18' />;
  //   case "20u":
  //     return <TeamsTabContent length='20' />;
  //   case "22u":
  //     return <TeamsTabContent length='22' />;
  //   case "24u":
  //     return <TeamsTabContent length='24' />;

  //   default:
  //     return <TeamsTabContent length='10' />;

  // }
  // };

  return (
    <div className='p-2'>
      <p className="text-white m-0 fw-bold fs-5 mt-5">
        Team Age Group
      </p>

      <Row>
        <Col className='p-2'>
          <Tabs tabs={ageGroup} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
          <span>Teams: {teams?.length}</span>
        </Col>
      </Row>

      {
        // tabContent() 
      }
      {/* Add Team Details */}
      {
        !data?.is_live ? <div className='d-flex justify-content-end'>
          <Button style={{ marginRight: '20px' }} onClick={() => setAddRefundModalShow(true)} >
            Refund
          </Button>


          <Button disabled={teams?.length === 4 || teams?.length === 8 || teams?.length === 16 || teams?.length === 32 ? false : true} onClick={() => setAddModalShow(true)} >
            ADD
          </Button>

        </div> : ''
      }


      <Row>
        <Col >
          {/* <img
            className='w-100 p-5'
            src={'/images/table_bracket.png'}
            alt="Table Bracket"
          /> */}
          {fixture['A'] ?
            <TreeComponent fixture={fixture} key={key} />
            : <div className='d-flex justify-content-center m-5'>No Fixture</div>
          }
        </Col>
      </Row>
      {addModalShow ?
        <AddTournamentComponent teams={teams} ageGroupId={key} setAddModalShow={setAddModalShow} handleAddModalClose={() => setAddModalShow(false)} />
        : ""}
      {addRefundModalShow ?
        <RefundPaymentModal teams={teams} handleAddModalClose={() => setAddRefundModalShow(false)} />
        : ""}
      {/* <ThemeModal className={""} title={"Delete"} size={"sm"}
        content={delModalShow ? <AddTournamentComponent /> : ''}
        show={delModalShow}
        onHide={() => setDelModalShow(false)} /> */}
    </div>
  )
}

export default TablesContent

import { apiUrl } from "../constants";
import api from "./indexGeneral";

export const getPlayerList = (params) =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.player}`, { params });

export const removePlayer = (id) =>
  api.delete(`${apiUrl.api}/${apiUrl.role}/${apiUrl.player}/${id}`);

export const unBanPlayer = (id) =>
  api.delete(`${apiUrl.api}/${apiUrl.role}/ban/${apiUrl.player}/${id}`);

export const banPlayer = (id) =>
  api.post(`${apiUrl.api}/${apiUrl.role}/ban/${apiUrl.player}/${id}`);

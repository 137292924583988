import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TournamentBanner from "../../Components/Cards/TournamentBanner";
import RegistrationCard from "../../Components/Cards/RegistrationCard";
import cssStyles from "./index.module.css";
import Tabs from "../../Components/Tabs/Tabs";
import { Button, CardTitle } from "../../Components/CustomComponents";
import DetailsContent from "./DetailsContent";
import TeamsContent from "./TeamsContent";
import TablesContent from "./TablesContent";
import RulesContent from "./RulesContent";
import { ROUTES } from "./Route";
import styles from "./Tournament.module.scss";
import ModalContainer from "../../Components/ModalContainer";
import LiveChatModal from "./Components/LiveChatModal";
import LiveMatchStats from "./Components/LiveMatchStats";
import BorderedCard from "../../Components/Cards/BorderedCard";
import { endMatch, getLiveMatches, getTournamentBySlug, startMatch } from "../../services/generalService";
import Toast from "../../Hooks/useToast";
import UpdateScoreModal from "./Components/UpdateScoreModal";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form"
import { CustomSelect } from "./Components/SelectFieldComponent";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Components/Modals/DeleteModal";
import { deleteTournament } from "../../services/TournamentService";
import CustomDeleteModal from "../../Components/Modals/NewDeleteCustomModal";



const TournamentDetailsScreen = ({ slug, setPage, live = false, GetScheduleTournament }) => {
    const { handleSubmit, control, register } = useForm()
    const navigate = useNavigate()
    const [key, setKey] = useState("details");
    const [showModal, setShowModal] = useState(false);
    const [isMatchModal, setMatchModal] = useState(false);
    const [isEndModal, setIsEndModal] = useState(false)
    const [data, setData] = useState([])
    const [liveMatches, setLiveMatches] = useState([])
    const [loading, setLoading] = useState(false)
    const [updateSlug, setUpdateSlug] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const Teams = [
        { value: updateSlug?.teamA_slug, label: updateSlug?.teamA_name },
        { value: updateSlug?.teamB_slug, label: updateSlug?.teamB_name }
    ]
    const array = [
        {
            value: "details",
            label: "Details",
        },
        {
            value: "tables",
            label: "Tables",
        },
        {
            value: "teams",
            label: "Teams",
        },
        {
            value: "rules",
            label: "Rules",
        },
    ];
    useEffect(() => {
        GetTournamentBySlug()
    }, [slug])
    useEffect(() => {
        GetLiveMatches()
    }, [slug, loading])


    const GetTournamentBySlug = async () => {
        const response = await getTournamentBySlug(slug)
        setData(response?.data?.data)
    }

    const GetLiveMatches = async () => {
        const response = await getLiveMatches(slug)
        setLiveMatches(response?.data?.data)
    }

    const tabContent = () => {
        switch (key) {
            case "details":
                return <DetailsContent data={data} live={live} />;
            case "tables":
                return <TablesContent data={data} />;
            case "teams":
                return <TeamsContent data={data} />;
            case "rules":
                return <RulesContent data={data} live={live} />;

            default:
                return <DetailsContent data={data} />;
        }
    };

    const handleBackButtonClck = () => {
        setPage(ROUTES.TOURNAMENT)
    };


    const handleMatchModalOpen = (data) => {
        setMatchModal(true);
        setUpdateSlug(data?.slug)
    }
    const handleEndMatchModalOpen = (data) => {
        setIsEndModal(true);
        setUpdateSlug(data)
    }
    const handleMatchModalClose = () => {
        setMatchModal(false);
    }
    // const handleMagicBox = () => {
    //     // console.log("changed");
    //     // alert('handleMagicBox');
    //     setPage(ROUTES.MAGIC_BOX);
    // };

    const handleEditTournament = () => {
        // console.log("changed");
        // alert('handleGetRegister');
        setPage(ROUTES.CREATE_TOURNAMENT);
    };

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // live matches
    const [index, setIndex] = useState(0); // Use state to manage the index

    const handlePreviousClick = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };

    const handleNextClick = () => {
        if (index < liveMatches.length - 1) {
            setIndex(index + 1);
        }
    };

    const onStart = async (data) => {
        data['match_slug'] = data?.slug
        data["score"] = {
            "home": 0,
            "away": 0,
            "ball": 0,
            "strike": 0,
            "out": 0,
            "inning": 1
        }
        try {
            const response = await startMatch(data)
            setLoading(!loading)
            Toast(response?.data?.message, "success");
        }
        catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
    }

    const onMatchEndSubmit = async (data) => {
        data['match_slug'] = updateSlug?.slug
        try {
            const response = await endMatch(data)
            setLoading(!loading)
            setIsEndModal(false)
            Toast(response?.data?.message, "success");
        }
        catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
    }
    const onUpdate = async (data) => {
        data['match_slug'] = updateSlug
        data["score"] = {
            "home": data?.home,
            "away": data?.away,
            "ball": data?.ball,
            "strike": data?.strike,
            "out": data?.out,
            "inning": data?.innings
        }
        try {
            const response = await startMatch(data)
            setMatchModal(false)
            setLoading(!loading)
            Toast(response?.data?.message, "success");
        }
        catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
    }

    const DeleteRournament = async (data) => {
        try {
            const response = await deleteTournament(data?.slug)
            Toast(response?.data?.message, "success");
            GetScheduleTournament()
            setPage(ROUTES.TOURNAMENT)
        } catch (error) {
            console.log(error, 'eroorrrorrrrrrrrrrrrrrrrrrrrr');
            Toast(error?.response?.data?.message, "error");
        }
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    }
    const handleselecteddata = (data) => {
        setDeleteModal(true)
        setSelectedData(data)
    }
    return (
        <>
            {!isMatchModal ? "" :
                <ModalContainer handleClose={handleMatchModalClose}>
                    <div className={styles.modalContainer}>
                        <BorderedCard>
                            <div className={styles.editMatchContainer}>
                                <CardTitle
                                    text="Live Score Update"
                                    classes='fs-5 fw-bold text-center mb-3'
                                />
                                <Form onSubmit={handleSubmit(onUpdate)}>
                                    <UpdateScoreModal control={control} liveMatches={liveMatches} index={index} handlePreviousClick={handlePreviousClick} handleNextClick={handleNextClick} />
                                    <div className={styles.buttonContainer}>
                                        <Button
                                            text="Cancel"
                                            classes="me-1 fs-6"
                                            style={{ background: "transparent", border: "1px solid white" }}
                                            onClick={handleMatchModalClose}

                                        />
                                        <Button
                                            text="Save"
                                            type="submit"
                                            classes="ms-1 fs-6"
                                        />
                                    </div>
                                </Form>
                            </div>
                        </BorderedCard>
                    </div>
                </ModalContainer >
            }
            {!isEndModal ? "" :
                <ModalContainer handleClose={() => setIsEndModal(false)}>
                    <div className={styles.modalContainer}>
                        <BorderedCard>
                            <div className={styles.editMatchContainer}>
                                <CardTitle
                                    text="End Match"
                                    classes='fs-5 fw-bold text-center mb-3'
                                />
                                <Form onSubmit={handleSubmit(onMatchEndSubmit)}>
                                    <Controller
                                        control={control}
                                        name="winner_slug"
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <CustomSelect placeholder='Select Team' options={Teams} onChange={onChange} />
                                        )} />
                                    <div className={styles.buttonContainer}>
                                        <Button
                                            text="Cancel"
                                            classes="me-1 fs-6"
                                            style={{ background: "transparent", border: "1px solid white" }}
                                            onClick={() => setIsEndModal(false)}

                                        />
                                        <Button
                                            text="Save"
                                            type="submit"
                                            classes="ms-1 fs-6"
                                        />
                                    </div>
                                </Form>
                            </div>
                        </BorderedCard>
                    </div>
                </ModalContainer >}
            {!live ?
                <>
                    <div className={cssStyles.ViewTournament}>
                        <Row>
                            <Col md={12} lg={8}>
                                <TournamentBanner hasBackButton={true}
                                    data={data}
                                    handleBackButtonClck={handleBackButtonClck} />
                            </Col>
                            <Col md={12} lg={4}>
                                <div className={cssStyles.topMargins}>
                                    <RegistrationCard data={data} />
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-2">
                            <span onClick={() => handleselecteddata(data)} className={`${styles.deleteBadge} mt-2`} ><MdDelete cursor={'pointer'} color="red" size={30} />DELETE</span>
                        </div>
                        <div className="mt-3">
                            <Row>
                                <Col
                                    xs={{ order: 2, span: 12 }}
                                    sm={{ order: 2, span: 12 }}
                                    md={{ order: 2, span: 12 }}
                                    lg={{ order: 1, span: 8 }}
                                    xl={{ order: 1, span: 8 }}
                                    xxl={{ order: 1, span: 8 }}
                                >
                                    <div className={cssStyles.beforeTabs}>
                                        <Tabs tabs={array} current={key} onChange={setKey} />
                                    </div>
                                </Col>
                                <Col
                                    xs={{ order: 1, span: 12 }}
                                    sm={{ order: 1, span: 12 }}
                                    md={{ order: 1, span: 12 }}
                                    lg={{ order: 2, span: 4 }}
                                    xl={{ order: 2, span: 4 }}
                                    xxl={{ order: 2, span: 4 }}
                                >
                                    {/* <Row>
                                    <Col>
                                        <Button
                                            classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn}`}
                                            text="Edit Tournament"
                                            onClick={handleEditTournament}
                                        ></Button>
                                    </Col>
                                </Row> */}
                                </Col>
                            </Row>
                        </div>

                        <div style={{ color: 'white', }}>
                            {tabContent()}
                        </div>
                    </div>
                </>

                :
                <>
                    <div className={styles.Tournament}>
                        {showModal ? <ModalContainer handleClose={handleCloseModal}>
                            <div className={cssStyles.modalContainer} style={{ width: '450px', height: '500px', zIndex: 2, }}>
                                <LiveChatModal onClick={handleCloseModal} />
                            </div>

                        </ModalContainer> : ''}
                        <Row className={styles.row}>
                            <Col
                                className={styles.item1}
                            >
                                <TournamentBanner
                                    data={data}
                                    hasBackButton={true}
                                    handleBackButtonClck={handleBackButtonClck}
                                />
                            </Col>
                            <Col
                                className={styles.item2}
                            >
                                {liveMatches?.length > 0 ?
                                    <div className={styles.infoContainer}>
                                        <div
                                            className={styles.subItem1}
                                        >
                                            <LiveMatchStats liveMatches={liveMatches} index={index} handlePreviousClick={handlePreviousClick} handleNextClick={handleNextClick} onStart={onStart} isEdit={false} onClick={handleMatchModalOpen} onMatchEnd={handleEndMatchModalOpen} />
                                        </div>
                                        <div
                                            className={styles.subItem2}
                                        >
                                            <LiveChatModal />
                                        </div>
                                    </div> : <p className={styles.noMatch}>No Live Match Found</p>}

                            </Col>

                            <Col
                                className={styles.item3}
                            >
                                <div className={cssStyles.beforeTabs}>
                                    <Tabs tabs={array} current={key} onChange={setKey} />
                                </div>
                                <div style={{ color: 'white', }}>
                                    {tabContent()}
                                </div>
                            </Col>
                        </Row>

                        <div className={`${styles.floatingButtonContainer}`}>
                            <Button
                                classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn} ${styles.floatingButton}`}
                                text="Live Chat"
                                onClick={handleButtonClick}
                            ></Button>
                        </div>

                    </div >
                </>
            }
            {deleteModal ? (
                <CustomDeleteModal
                    handleDeleteModalClose={handleDeleteModalClose}
                    onDelete={DeleteRournament}
                    data={selectedData}
                    deletetxt={"Delete"}
                />
            ) : (
                ""
            )}
        </>
    )
}

export default TournamentDetailsScreen

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import { GetTokenLocalStorage } from "../../services/Localstorage";

function ProtectedRoute({ children }) {

    const token = GetTokenLocalStorage()
    return (
        token ? children : <Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} replace />
    );
}

export default ProtectedRoute;
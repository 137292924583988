import React from "react";
import { useForm, Controller } from "react-hook-form"
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

import { Button, CustomInput, Description, Input, Title } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";
import Toast from "../../Hooks/useToast";
import { ForgetPassword } from "../../services/auth";

export default function ForgotPassword({ setPage }) {
    const { handleSubmit, control, register } = useForm()
    const navigate = useNavigate()
    const handleBackButtonClick = () => {
        setPage(ACCOUNT_ENUM.LOGIN)
    }

    const handleDataSubmit = async (userData) => {
        console.log(userData, 'user data`');
        try {
            // Use unwrap() to get the response data directly
            const response = await ForgetPassword(userData)
            Toast(response?.data?.message, "success");
            navigate('/account')
            // dispatch(updateLogin(true))
        } catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            <Title text="Forgot Password" classes="mb-2" />
            <Description
                text="In order to reset your password you need to enter your registered email address."
                classes="text-center fs-7"
            />
            <Form onSubmit={handleSubmit(handleDataSubmit)}>
                <Controller
                    control={control}
                    name="email"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <InputGroup className="mb-3"
                            style={{
                                width: '300px'
                            }}
                        >
                            <CustomInput
                                placeholder="Email" type="email" onChange={onChange} />
                        </InputGroup>
                    )}
                />
                <Button
                    text='Continue'
                    classes="mt-3 mb-2"
                    type='submit'
                />
            </Form>
        </AccountLayout>
    )
}
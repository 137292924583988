import React from "react";

import styles from "./styles/Layout.module.scss"

import Menu from "./Menu";

import { Col, Row } from "react-bootstrap";
import Details from "./Details";

export default function DesktopLayout({ route, setRoute }) {
    return (
        <div className={styles.Layout}>
            <Row className={styles.row}>
                <Col
                    className={styles.col}
                    md={4}
                >
                    <Menu route={route} setRoute={setRoute} />
                </Col>
                <Col
                    className={styles.col}
                    md={8}
                >
                    <Details route={route} />
                </Col>

            </Row>

        </div>
    )
}
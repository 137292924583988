import React, { useEffect, useState } from 'react'
import Tabs from '../../Components/Tabs/Tabs'
import { Col, Row } from 'react-bootstrap';
import TeamsTabContent from './TeamsTabContent';
import { getTeamsByAgeGroup } from '../../services/generalService';

const TeamsContent = ({ data }) => {
  const [teams, setTeams] = useState([])
  const ageGroup = data?.age_groups
  const defaultAgeGroup = ageGroup.length > 0 ? ageGroup[0]?.value : 0;
  const [key, setKey] = useState(defaultAgeGroup);

  useEffect(() => {
    if (key !== null) {
      GetTeamsByAgeGroup()
    }
  }, [key])

  const GetTeamsByAgeGroup = async () => {
    const res = await getTeamsByAgeGroup(key)
    setTeams(res?.data?.data)
  }

  const inlineItemStyles = {
    background: '#222232',
    margin: '5px',
    padding: '10px 20px',
  };


  const tabContent = () => {
    switch (key) {
      case "6u":
        return <TeamsTabContent length='6' />;
      case "8u":
        return <TeamsTabContent length='8' />;
      case "10u":
        return <TeamsTabContent length='10' />;
      case "12u":
        return <TeamsTabContent length='12' />;
      case "14u":
        return <TeamsTabContent length='14' />;
      case "16u":
        return <TeamsTabContent length='16' />;
      case "18u":
        return <TeamsTabContent length='18' />;
      case "20u":
        return <TeamsTabContent length='20' />;
      case "22u":
        return <TeamsTabContent length='22' />;
      case "24u":
        return <TeamsTabContent length='24' />;

      default:
        return <TeamsTabContent length='10' />;

    }
  };


  return (
    <div className='p-2'>
      {/* <SubTitle text='Teams Age Group' /> */}
      <p className="text-white m-0 fw-bold fs-5 mt-5">
        Team Age Group
      </p>
      <Row>
        <Col className='p-2'>
          <Tabs tabs={ageGroup} current={key} onChange={setKey} itemStyle={inlineItemStyles} containerStyles={{ width: 'fit-content' }} />
        </Col>
      </Row>


      <TeamsTabContent teams={teams} />

    </div>
  )
}

export default TeamsContent

import React from "react";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/notification.module.css";
import { FaCircleXmark } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import { refundTeam } from "../../services/generalService";
import Toast from "../../Hooks/useToast";

const CustomDeleteModal = ({ handleDeleteModalClose, deletetxt, data, onDelete }) => {
    console.log(data, 'dataaaaaaaaaaaa2111');
    return (
        <div>
            <ModalContainer handleClose={handleDeleteModalClose}>
                <div className={cssStyles.modalContainer}>
                    <div className="d-flex align-items-center w-100 justify-content-between mb-4">
                        <p className="fs-4 fw-medium m-0">Confirm Delete</p>
                        <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
                            <FaCircleXmark
                                role="button"
                                onClick={handleDeleteModalClose}
                                style={{ cursor: "pointer" }}
                            />
                        </span>
                    </div>
                    <div>
                        <p>
                            Do you want to delete <b> {data?.name} </b> ?{" "}
                        </p>

                        <div className="d-flex justify-content-center align-items-center">
                            <div
                                className="d-flex justify-content-center align-items-center rounded-3 p-2 mx-2 bg-dark"
                                role="button"
                                onClick={handleDeleteModalClose}
                            >
                                <span className={`mx-2 d-flex`}>Cancle</span>
                            </div>

                            <div
                                className="d-flex justify-content-center align-items-center rounded-3 p-2 mx-2 bg-danger"
                                role="button"
                                onClick={() => {
                                    onDelete(data);
                                }}
                            >
                                <span className={`mx-2 d-flex`}>{deletetxt}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default CustomDeleteModal;

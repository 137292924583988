import React, { useEffect, useState } from "react";
import cssStyles from "./index.module.css";
import { ROUTES } from "./Route.js";
import { Button, SubTitle, } from "../../Components/CustomComponents/index.js";
import { Col, Row, } from "react-bootstrap";
import TournamentBanner from "../../Components/Cards/TournamentBanner.js";
import ApiLoader from "./Components/SpinnerLoader.jsx";


export default function TournamentManagementScreen({ isApiLoading, setSlug, liveData, scheduleData, setPage }) {


  const handleViewScheduledTournaments = () => {
    setPage(ROUTES.SCHEDULED_TOURNAMENTS);
  };

  const handleViewLiveTournaments = () => {
    setPage(ROUTES.LIVE_TOURNAMENTS)
  }

  const gotoLiveTournamentDetails = () => {
    setPage(ROUTES.LIVE_TOURNAMENT_DETAILS);
  }

  const gotoTournamentDetails = (data) => {
    setSlug(data?.slug)
    setPage(ROUTES.TOURNAMENT_DETAILS);
  }

  const gotoCreateTournamentScreen = () => {
    setPage(ROUTES.CREATE_TOURNAMENT);
  }


  return (
    <div className={cssStyles.tournament}>
      <div className={cssStyles.headerContainer}>
        <div>
          <SubTitle text={"Tournament"} />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${cssStyles.cardBtn} `}
              text="+ Create Tournament"
              onClick={gotoCreateTournamentScreen}
            ></Button>
          </Col>
        </Row>
      </div>
      <br></br>
      <div className={cssStyles.headerContainer}>
        <div>
          <SubTitle classes="fs-5" text={"Live Tournaments"} />
        </div>
        <button
          className={cssStyles.viewButton}
          onClick={handleViewLiveTournaments}
        >
          View All
        </button>
      </div>
      <Row
        className={`w-100 mt-2 mb-5`}
        gutter={[0, 24]}
      >
        {isApiLoading ? <ApiLoader className='spinner' /> :
          liveData?.map(item => <Col
            className={` mt-2 mb-2`}
            lg={6} md={6} sm={12}
          >
            <TournamentBanner data={item} hasBackButton={false} live={true} handleOnClick={() => {
              gotoTournamentDetails(item)
              setPage(ROUTES.LIVE_TOURNAMENT_DETAILS)
            }
            } />
          </Col>)}
      </Row>
      <div className={cssStyles.headerContainer}>
        <div>
          <SubTitle classes="fs-5" text={"Scheduled Tournaments"} />
        </div>
        <button
          className={cssStyles.viewButton}
          onClick={handleViewScheduledTournaments}
        >
          View All
        </button>
      </div>
      <Row
        className={`w-100 mt-2 mb-5`}
        gutter={[0, 24]}
      >
        {isApiLoading ? <ApiLoader className='spinner' /> :
          scheduleData?.map(item =>
            <Col
              className={` mt-2 mb-2`}
              lg={6} md={6} sm={12}
            >
              <TournamentBanner data={item} hasBackButton={false} handleOnClick={() => gotoTournamentDetails(item)} />
            </Col>)}
      </Row>
    </div>
  );
}
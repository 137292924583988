import React from 'react';

import styles from "./styles/Dashboard.module.scss"

import { SubTitle } from '../../Components/CustomComponents';
import { Row, Col } from 'react-bootstrap';
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { ROUTES } from './constants';
import DashboardCard from '../../Components/Cards/DashboardCard';
import { CiUser } from "react-icons/ci";
import { RiParentFill } from "react-icons/ri";
import { FaUserNurse } from "react-icons/fa6";
import { GiBabyfootPlayers } from "react-icons/gi";
import { TbTournament } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { GiTeamDowngrade } from "react-icons/gi";


export default function Dashboard({ setPage }) {
    const handleViewLiveMatches = () => {
        setPage(ROUTES.LIVE_MATCHES)
    }

    const handleViewTournament = () => {
        setPage(ROUTES.TOURNAMENTS)
    }

    return (
        <div className={styles.Dashboard}>

            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <SubTitle
                        text={'Dashboard'}
                    />
                </div>
            </div>

            <Row
                className={`${styles.matchRow} w-100 mt-2 mb-5`}
            >
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#860303'}
                        bg2={'#ff0000'}
                        icon={<FaUserNurse color='#860303' />}
                        number={252}
                        text={'Numbers of Coaches'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#202670'}
                        bg2={'#b83666'}
                        icon={<RiParentFill color='#b83666' />}
                        number={252}
                        text={'Numbers of Parents'} />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#093e22'}
                        bg2={'#4eba33'}
                        icon={<GiBabyfootPlayers color='#4eba33' />}
                        number={252}
                        text={'Numbers of Player'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#e63e19'}
                        bg2={'#f5a51a'}
                        icon={<TbTournament color='#f5a51a' />}
                        number={252}
                        text={'Numbers of Tournaments'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#0a4caa'}
                        bg2={'#3d87f4'}
                        icon={<GiTeamDowngrade color='#3d87f4' />}
                        number={252}
                        text={'Numbers of Matches'}
                    />
                </Col>
                <Col
                    className={`mt-2 mb-2`}
                    lg={4} md={4} sm={6}
                >
                    <DashboardCard
                        bg1={'#0a4caa'}
                        bg2={'#3d87f4'}
                        icon={<FaUsers color='#3d87f4' />}
                        number={252}
                        text={'Numbers of users'}
                    />
                </Col>
            </Row>






        </div>
    )
}


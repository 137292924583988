import { apiUrl } from "../constants";
import api from "./indexGeneral";

export const getCoachList = (params) =>
  api.get(`${apiUrl.api}/${apiUrl.role}/${apiUrl.coach}`, { params });

export const removeCoach = (id) =>
  api.delete(`${apiUrl.api}/${apiUrl.role}/${apiUrl.coach}/${id}`);

export const unBanCoach = (id) =>
  api.delete(`${apiUrl.api}/${apiUrl.role}/ban/${apiUrl.coach}/${id}`);

export const banCoach = (id) =>
  api.post(`${apiUrl.api}/${apiUrl.role}/ban/${apiUrl.coach}/${id}`);

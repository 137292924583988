import React, { useEffect, useState } from "react";
import styles from "./styles/user_management.module.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import axios from "axios";
import { GrView } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import Table from "../../Components/Table/Table";
import { FaUserEdit } from "react-icons/fa";
import Toast from "../../Hooks/useToast";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditPlayerModal from "../../Components/Modals/EditPlayerModal";
import { LiaBanSolid } from "react-icons/lia";
import { getCoachList } from "../../services/coach";
import { banPlayer, getPlayerList, unBanPlayer } from "../../services/player";
import { FaBackward, FaForward } from "react-icons/fa6";
import { IoCaretBackSharp, IoCaretForwardSharp } from "react-icons/io5";
import ApiLoader from "../TournamentManagement/Components/SpinnerLoader";

const PlayerManagementScreen = ({}) => {
  const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isBanned, setIsBanned] = useState(false);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetPalyerList();
  }, [page]);

  const GetPalyerList = async () => {
    let params = {
      page: page,
    };
    try {
      let result = await getPlayerList(params);
      setData(result?.data?.data);
      setPaginationDetail(result?.data?.links);
      setIndicationMessage("");
    } catch (e) {
      Toast(e?.response?.message, "error");
    }
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setRowData("");
  };

  const deleteData = (id) => {
    console.log(id);
  };

  const handleBannedonClick = async (data) => {
    let result;
    try {
      if (data?.is_banned) {
        result = await unBanPlayer(data?.slug);
      } else {
        result = await banPlayer(data?.slug);
      }
      GetPalyerList();
    } catch (e) {
      Toast(e?.response?.data?.message);
    }
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "age",
      text: "Age",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return `${row?.age} years`;
      },
    },
    {
      dataField: "dateOfBirth",
      text: "Date of Birth",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "150px",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <>
              <div className="d-flex">
                <div
                  className={`${
                    loading
                      ? styles.btnBgdiabled // Disabled style when loading
                      : row?.is_banned
                      ? styles.btnBgunban // Style for unban
                      : styles.btnBgban // Style for ban
                  } d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 m-1`}
                  role="button"
                  style={{
                    width: "150px",
                  }}
                  onClick={() => {
                    if (!loading) {
                      // Prevent onClick when loading
                      handleBannedonClick(row);
                    }
                  }}
                >
                  <span className="align-items-center d-flex justify-content-center pe-1">
                    <LiaBanSolid />
                  </span>
                  <span>
                    {loading ? <ApiLoader /> : row?.is_banned ? "UNBAN" : "BAN"}
                  </span>
                </div>
              </div>
            </>
          );
        }
      },
    },
  ];

  const handleBackPaginagtion = () => {
    if (paginationDetail?.current != 1) {
      setPage(page - 1);
    }
  };
  const handleForwardPaginagtion = () => {
    if (paginationDetail?.current != paginationDetail?.total) {
      setPage(page + 1);
    }
  };
  function indication() {
    // return 'Table is empty';
    return indicationMessage;
  }

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={rowData}
          onDelete={deleteData}
          deletetxt={"Remove"}
        />
      ) : (
        ""
      )}

      {
        //     editModal ? (
        //     <EditPlayerModal
        //         handleEditModalClose={handleEditModalClose}
        //         data={rowData}
        //     />
        // ) : (
        //     ""
        // )
      }

      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Player Management"} />
        </div>
      </div>
      <div className={styles.mainTable}>
        <Table data={data} columns={columns} indication={indication} />
        <div
          className="pagination"
          style={{
            fontSize: "15px",
            color: "white",
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            marginRight: "12px",
          }}
        >
          <FaBackward
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={() => setPage(1)}
          />
          <IoCaretBackSharp
            cursor={"pointer"}
            color={paginationDetail?.current == 1 ? "gray" : "white"}
            size={20}
            onClick={handleBackPaginagtion}
          />
          <span>
            {paginationDetail?.current}/{paginationDetail?.total}
          </span>
          <IoCaretForwardSharp
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={handleForwardPaginagtion}
          />
          <FaForward
            cursor={"pointer"}
            color={
              paginationDetail?.current === paginationDetail?.total
                ? "gray"
                : "white"
            }
            size={20}
            onClick={() => setPage(paginationDetail?.total)}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerManagementScreen;

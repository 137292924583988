import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";

import styles from "./styles/ChangePassword.module.scss";
import {
  Button,
  CustomInput,
  Description,
  Input,
  SubTitle,
  UnorderedList,
} from "../../Components/CustomComponents";
import Toast from "../../Hooks/useToast";
import { changePassword } from "../../services/auth";

export default function ChangePassword() {
  const { handleSubmit, control, register } = useForm();
  const handleDataSubmission = async (userData) => {
    try {
      // Use unwrap() to get the response userData directly
      const response = await changePassword(userData);
      console.log(response);
      Toast(response?.data?.message, "success");
    } catch (error) {
      console.log(error, "thissssssss");
      Toast(error?.response?.data?.message, "error");
    }
  };
  return (
    <div className={styles.ChangePassword}>
      <SubTitle text="Change Password" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="fs-8 mb-4"
      />
      <Form onSubmit={handleSubmit(handleDataSubmission)}>
        <div className={styles.form}>
          <Description text="Current Password" classes="mb-1" />
          <Controller
            control={control}
            name="current_password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Current Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <Description text="Set New Password" classes="mt-3 mb-1" />
          <Controller
            control={control}
            name="new_password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="New Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="confirm_password"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomInput
                placeholder="Confirm Password"
                type="password"
                onChange={onChange}
              />
            )}
          />
          <UnorderedList
            lists={[
              "At least 12 characters long but 14 or more is better.",
              "A combination of uppercase letters, lowercase letters, numbers, and symbols.",
            ]}
            classes="fs-6 mt-4 mb-4"
          />

          <div className={styles.saveButton}>
            <Button
              text="Change Password"
              classes="fs-6 mb-2 mt-auto"
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

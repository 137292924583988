import React, { useState } from "react";

import { ROUTES } from "./Route";
import Login from "../Account/Login";
import TeamManagementScreen from "./TeamManagementScreen";

export default function TeamManagement() {
  const [page, setPage] = useState(ROUTES.TEAM_MANAGEMENT);

  switch (page) {
    case ROUTES.TEAM_MANAGEMENT:
      return <TeamManagementScreen setPage={setPage} />;
    default:
      return <Login />;
  }
}

import React from "react";

import styles from "./styles/Navbar.module.scss";
import { IoIosNotificationsOutline } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";

export default function Navbar({
  isSearchBar = true,
  isSettingButton = false,
  handleToggle,
}) {
  return (
    <div className={styles.Navbar}>
      <div className={styles.titleContainer}>
        <span onClick={handleToggle} className={styles.toggleButton}>
          <GiHamburgerMenu />
        </span>
        {/* <h3 className={styles.title}>{title}</h3> */}
      </div>
      {isSearchBar ? (
        <div className={styles.search}>
          <div className={styles.searchButton}>
            <AiOutlineSearch />
          </div>
          <input
            type="text"
            placeholder="Search tournament and matches"
            // value={search}
            // onChange={(e) => dispatch(setValue(e.target.value))}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export const BASE_URL_V1 =
  "http://api.primetimetournament.trangotechdevs.com:3009/";
export const BASE_URL_V2 = "https://dummyjson.com";

export const WEB_ROUTES = {
  ACCOUNT: "account",
  DASHBOARD: "dashboard",
  TOURNAMENT_MANAGEMENT: "tournament-management",
  COACH_MANAGEMENT: "coach-management",
  PLAYER_MANAGEMENT: "player-management",
  USER_MANAGEMENT: "user-management",
  TEAM_MANAGEMENT: "team-management",
  TEAM_DETAIL_MANAGEMENT: "team-detail-management",
  SETTING: "settings",
};

export const LOCAL_STORAGE = {
  setUser: "PTTUSER",
  setToken: "PTTTOKEN",
};

export const BREAKPOINTS = {
  MOBILE: 991,
};
export const apiUrl = {
  team: "team",
  coach: "coach",
  member: "member",
  auth: "auth",
  api: "api",
  role: "admin",
  user: "user",
  login: "login",
  forgotPass: "forgot-password",
  changePass: "change-password",
  // tournament
  tournament: "tournament",
  livetournament: "tournament/live",
  scheduletournament: "tournament/schedule",
  ageGroup: "age-group",
  refund: "refund",
  startmatch: "match/start",
  endmatch: "match/end",
  player: "player",
  unregister: "unregister",
};

// DATA ->
// DUMMY
export const sportsStateList = [
  { value: 1, label: "State 1" },
  { value: 2, label: "State 2" },
  { value: 3, label: "State 3" },
];

export const sportsTypeList = [
  { value: "Baseball", label: "Baseball" },
  { value: "fast pitch", label: "Fast Pitch" },
];
export const TeamCheckList = [
  { value: "unregister", label: "Unregister" },
  { value: "register", label: "Register" },
];

export const formateTTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  const timeFormatted = date.toLocaleString("en-US", options);
  return timeFormatted;
};

export const formatedDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric',
    // minute: '2-digit',
    // hour12: true
  };
  const dateFormatted = date.toLocaleString("en-US", options);
  return dateFormatted;
};

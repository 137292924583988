import React, { useState } from 'react';

import styles from './LiveMatchStats.module.scss'
import cssStyles from "../index.module.css";

import BorderedCard from '../../../Components/Cards/BorderedCard';
import { Button, CardDescription, CardTitle, LiveBadge, Score } from '../../../Components/CustomComponents';
import { IoArrowForwardSharp } from "react-icons/io5";
import { GrLinkPrevious } from 'react-icons/gr';
import { startMatch } from '../../../services/generalService';
import Toast from '../../../Hooks/useToast';

export default function LiveMatchStats({ liveMatches, index, handlePreviousClick, handleNextClick, onStart, isEdit = false, onClick = () => { }, onMatchEnd = () => { } }) {
    console.log(liveMatches?.length, index, '21222');


    return (
        <>
            <GrLinkPrevious onClick={handlePreviousClick} className={`${index === 0 ? 'd-none' : ''}`} />
            < BorderedCard >
                <div className={`${styles.matchInfo} ${!isEdit ? styles.editable : ""}`} >
                    <div className={styles.teamContainer}>
                        <div className={styles.team}>
                            <img
                                src={liveMatches[index]?.teamA_image_url}
                                alt='Team 1'
                                className={`${styles.logo}`}
                            />

                            <CardTitle
                                text={liveMatches[index]?.teamA_name}
                                classes="fs-8"
                            />
                        </div>
                        <div className={styles.seperator}>
                            <LiveBadge classes="mb-1" />
                            <CardTitle
                                text={`Round ${liveMatches[index]?.round}`}
                                classes='fs-5'
                            />
                            <CardDescription
                                text={liveMatches[index]?.start_date}
                                classes="fs-8"
                            />
                            <span className={styles.vs}>VS</span>
                        </div>
                        <div className={styles.team}>
                            <img
                                src={liveMatches[index]?.teamB_image_url}
                                alt='Team 1'
                                className={`${styles.logo}`}
                            />

                            <CardTitle
                                text={liveMatches[index]?.teamB_name}
                                classes="fs-8"
                            />
                        </div>
                    </div>
                    {liveMatches[index]?.is_started ?
                        <>
                            <div className={styles.statsContainer}>
                                <div className={styles.team}>
                                    <CardTitle
                                        text="Away"
                                        classes="mb-1"
                                    />
                                    <Score
                                        text={liveMatches[index]?.score?.away}
                                        classes="p-2 fw-normal fs-5"
                                    />
                                </div>
                                <div className={styles.stats}>
                                    <div className={styles.stat}>
                                        <Score text={liveMatches[index]?.inning} classes="p-2 fs-8" />
                                        <CardDescription text='Innings' />
                                    </div>
                                    <div className={styles.ballContainer}>
                                        <div className={styles.stat}>
                                            <Score text={liveMatches[index]?.score?.ball} classes="p-2 fs-8" />
                                            <CardDescription text={'Ball'} />
                                        </div>
                                        <div className={styles.stat}>
                                            <Score text={liveMatches[index]?.score?.strike} classes="p-2 fs-8" />
                                            <CardDescription text='Strike' />
                                        </div>
                                        <div className={styles.stat}>
                                            <Score text={liveMatches[index]?.score?.out} classes="p-2 fs-8" />
                                            <CardDescription text='Out' />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.team}>
                                    <CardTitle
                                        text="Home"
                                        classes="mb-1"
                                    />
                                    <Score
                                        text={liveMatches[index]?.score?.home}
                                        classes="p-2 fw-normal fs-5"
                                    />
                                </div>
                            </div>
                            <Button
                                classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn} ${styles.floatingButton}`}
                                text="Update Score"
                                className={'mt-2'}
                                onClick={() => onClick(liveMatches[index])}
                            // onClick={() => onStart(liveMatches[index])}
                            ></Button>
                            <Button
                                classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn} ${styles.floatingButton}`}
                                text="End Match"
                                className={'mt-2'}
                                onClick={() => onMatchEnd(liveMatches[index])}
                            // onClick={() => onStart(liveMatches[index])}
                            ></Button>
                        </>
                        :
                        <Button
                            classes={`${cssStyles.panelBtn} ${cssStyles.cardBtn} ${styles.floatingButton}`}
                            text="Start"
                            className={'mt-2'}
                            onClick={() => onStart(liveMatches[index])}
                        ></Button>
                    }
                </div>
            </BorderedCard >
            <IoArrowForwardSharp onClick={handleNextClick} className={`${liveMatches?.length - 1 === index ? 'd-none' : ''}`} />
        </>

    )
}
import React from 'react'
import styles from './liveChat.module.scss';
import { FiSend } from "react-icons/fi";
import { CardTitle, Input } from '../../../Components/CustomComponents';

const LiveChatModal = () => {
    return (
        <div className={styles.liveChat}>
            <CardTitle
                text="Live Chat"
                classes="fs-4 mb-2 fw-bold"
            />
            <div className={styles.chatContainer}>
                {[1, 1, 1, 1, 1, 1].map((item, index) => {
                    return (
                        <div
                            className={`${styles.chat} my-3`}
                            key={index}
                        >
                            <div className={styles.userImage}>
                                <img
                                    src={'/images/profile.png'}
                                    alt={`User Profile ${index}`}
                                    className={styles.image}
                                />
                            </div>
                            <div className={styles.userInfo}>
                                <CardTitle
                                    text="Fergus Douchebag"
                                    classes="fs-7 mb-1"
                                />
                                <CardTitle
                                    text="Sed consectetur vita Sed consectetur vita Sed consectetur vita Sed consectetur vita"
                                    classes="fs-8 fw-lighter"
                                />
                            </div>
                        </div>
                    )
                }
                )}
            </div>
            <div className={styles.messageContainer}>
                <Input
                    classes="px-3 py-1 fs-7"
                    size="sm"
                    placeholder="Type a message"
                />
                <span className={styles.sendButton}><FiSend /></span>
            </div>
        </div>
    )
}

export default LiveChatModal

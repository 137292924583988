import React, { useState } from "react";

import styles from './styles/TournamentBanner.module.scss'

import BorderedCard from "./BorderedCard";
import { CardTitle, Description } from "../CustomComponents";
import { MdDelete } from "react-icons/md";

import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import TimeBadge from "../Badges/TimeBadge";
import { deleteTournament } from "../../services/TournamentService";
import { rest } from "lodash";
import Toast from "../../Hooks/useToast";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Modals/DeleteModal";

export default function TournamentBanner({
    hasBackButton = false,
    live = false,
    handleBackButtonClck = () => { },
    handleOnClick = () => { },
    data
}) {
    const navigate = useNavigate()
    const [deleteModal, setDeleteModal] = useState(false)
    const formateTTime = (dateStr) => {
        const date = new Date(dateStr);
        const options = { hour: 'numeric', minute: '2-digit', hour12: true };
        const timeFormatted = date.toLocaleString('en-US', options);
        return timeFormatted
    }

    const DeleteRournament = async (data) => {
        try {
            const response = await deleteTournament(data?.slug)
            Toast(response?.data?.message, "success");
            navigate("/tournament-management");
        } catch (error) {
            Toast(error?.response?.data?.message, "error");
        }
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    }

    return (

        < BorderedCard >
            <div className={styles.TournamentBanner} key={data?.slug} onClick={() => handleOnClick(data?.slug)} role="button" >
                {hasBackButton ?
                    <span
                        className={styles.backButton}
                        onClick={handleBackButtonClck}
                    >
                        <IoIosArrowBack />
                    </span>
                    : ''}
                <img
                    src={data?.media_url}
                    alt="Card Banner"
                    className={styles.bannerImage}
                />

                <div className={styles.shadowContainer}></div>

                <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}>

                    <TimeBadge text={formateTTime(data?.start_date)} color='#0AA7FF' textColor="white" />

                    <TimeBadge text={data?.sports_type} color='#0AA7FF' textColor="white" />
                </div>


                <div className={styles.content}>


                    <img
                        src={data?.thumbnail_url}
                        alt="Tournament Logo"
                        className={styles.logo}
                    />
                    <div className={styles.info}>
                        <div className={styles.titleContainer}>
                            <CardTitle
                                text={data?.name}
                                classes="lc-1 fs-3"
                            />
                            {
                                live ?
                                    <span className={styles.liveBadge}>Live</span>
                                    : ''


                            }
                        </div>
                        <div className={styles.descriptionContainer}>

                            <span className={styles.mapIcon}><FaMapMarkerAlt /></span>
                            <Description
                                text={data?.location}
                                classes="fs-9 lc-1"
                            />
                        </div>

                    </div>

                </div>




            </div>
        </BorderedCard >
    )
}